<template>
  <div :class="['master-detail', { 'edit-mode': editMode, 'customize-pinned': customizePinned }, $attrs.class]">
    <slot name='feature-description' v-if="!editMode" />

    <grid-tools
      :searchEnabled="searchEnabled"
      :filterEnabled="filterEnabled"
      :refreshEnabled="true"
      v-model:filterOpen="filterOpen"
      :allColumns="allColumnDefsInPreferredOrderForMenu"
      :columnLabelKey="columnLabelKey"
      :columnIsSelected="columnIsVisible"
      :resourceName="resourceName"
      :addResourceText="addResourceText"
      :addItemEnabled="addItemEnabled"
      :exportEnabled="gridRowData.length > 0"
      :importEnabled="importEnabled"
      :icon="icon"
      :filterInUse="filterAppearsInUse"
      :searchText="searchInputText"
      :disableControls="disableControls"
      :allowBulkSelect="allowBulkSelect && !gridEmpty"
      :bulkSelectOptions="bulkSelectOptions"
      :bulkSelectOptionsDisabled="bulkSelectCount < 1"
      :bulkImportConfig="bulkImportConfig"
      :bulkSelectOn="bulkSelectOn"
      :customExportOptions="customExportOptions"
      @applySearch="applySearch"
      @applyFilter="applyFilter(true)"
      @clearFilter="clearFilter"
      @addItem="addItem"
      @selectedColumnsUpdated="columnPrefsUpdated"
      @print="exportGrid('print')"
      @csv="exportGrid('csv')"
      @excel="exportGrid('excel')"
      @pdf="exportGrid('pdf')"
      @bulk-select-clicked="bulkSelectOn = !bulkSelectOn"
      @bulk-action-selected="bulkActionSelected"
      @bulk-import-completed="bulkImportCompleted"
      @reset-columns-to-defaults="resetColumnsToDefaults"
      @export-to-custom="exportToCustom"
      @refresh="search()"
      @customize-pinned="customizePinned = $event"
    >
      <template #quick-filter="scope">
        <slot name="quick-filter" v-bind="scope"/>
        <b-col cols="auto">
          <b-checkbox v-if="clientSideActiveFilterEnabled" v-model="showActiveOnly" :disabled="disableControls" class="show-active-only">
            Show active only
          </b-checkbox>
        </b-col>
      </template>
      <template #filter-form>
        <slot name="filter-form" :disabled="disableControls" />
      </template>
    </grid-tools>

    <b-alert v-if="entirePageBulkSelected" :model-value="true" variant="info" class="bulk-select-page-alert">
      All {{ bulkSelectCount }} {{ pluralResourceName }} on this page are selected.
      Push the "More results" button below the list in order to load and select all {{ pluralResourceName }}.
    </b-alert>

    <slot name="before-grid" />

    <ag-grid-vue
        class="master-grid"
        :gridOptions="gridOptions"
        :columnDefs="finalGridColumnDefs"
        :rowData="gridRowData"
        :selectionColumnDef="selectionColumnDef"
        :enableFilter="false"
        :sideBar="false"
        :suppressCellFocus="true"
        :suppressMenuHide="false"
        :rowSelection="bulkSelectOn ? { mode: 'multiRow', enableSelectionWithoutKeys: true } : null"
        :loadingOverlayComponentParams="loadingOverlayComponentParams"
        noRowsOverlayComponent="noRowsOverlay"
        :noRowsOverlayComponentParams="noRowsOverlayComponentParams"
        :getRowHeight="getRowHeight"
        :getRowClass="getRowClass"
        :excelStyles="excelStyles"
        :defaultColDef="{ suppressHeaderMenuButton: true }"
        @grid-ready="onGridReady"
        @row-clicked="rowClicked"
        @column-moved="columnMoved"
        @column-visible="columnVisible"
        @column-resized="columnResized"
        @selection-changed="selectionChanged"
        @row-data-changed="rowDataChanged"
        @row-data-updated="rowDataUpdated"
        @sort-changed="sortChanged"
    />

    <more-results
      :loading="loadingMoreResults"
      :canLoadMore="!!cursor && !isPrinting"
      :disabled="disableControls"
      :allowAll="allowLoadAll"
      @load="loadNextPage"
    />

    <slot name="after-grid" />

    <!-- TODO: Mask doesn't look good when detail form scrolls, because detail form uses absolute css,
               and thus its height is only the viewable height rather than full scrollable height.
               We'll try to use a toast instead where we can. -->
    <detail-form
      ref="detailForm"
      v-show="editMode"
      v-mask="maskOptions"
      :listNavigation="listNavigation"
      :orchestrator="orchestrator"
      :saving="saving"
      :deleting="deleting"
      :saveEnabled="saveEnabled"
      :deleteEnabled="deleteEnabled"
      :denormEnabled="denormEnabled"
      @nextItem="nextItem"
      @prevItem="prevItem"
      @save="saveEdit"
      @cancel="cancelEdit"
      @abortSave="abortSave"
      @delete="deleteItem"
      @return-to-list="returnToList(true)"
      @apply-grid-transaction="applyGridTransaction"
    >
      <template #header-edit-title="scope">
        <slot name="header-edit-title" v-bind="scope"/>
      </template>
      <template #default="scope">
        <slot name="detail-form" v-bind="scope" :isFormOpen="editMode" />
      </template>
      <template #footer>
        <slot name="detail-footer" />
      </template>
      <template #after-footer="scope">
        <slot name="after-detail-footer" v-bind="scope" />
      </template>
    </detail-form>
  </div>
</template>
<script>
import ExportsCustom from '@/mixins/ExportsCustom'
import ManagesAgGrid from '@/mixins/ManagesAgGrid'
import Maskable from '@/mixins/Maskable'
import AgGridVue from '@/components/grid/ag-grid-vue'
import DetailForm from '@/components/grid/DetailForm.vue'
import GridTools from '@/components/grid/GridTools.vue'
import MoreResults from '@/components/grid/MoreResults.vue'
import SavePrompt from '@/components/SavePrompt.vue'
import { useModalController } from 'bootstrap-vue-next'
import _ from 'lodash'
import { extractErrorMessage } from '@/utils/misc'
import { pluralize } from 'inflection'
import storage from '@/utils/SafeLocalStorage'

// TODO: Try to refactor to use EnhancedGrid with detail functionality
// TODO: in a separate component.

export default {
  setup () {
    const modalController = useModalController()
    return {
      confirmModal: modalController.confirm,
      showModal: modalController.show
    }
  },
  inheritAttrs: false,
  mixins: [
    ExportsCustom,
    ManagesAgGrid,
    Maskable
  ],
  components: {
    AgGridVue,
    DetailForm,
    GridTools,
    MoreResults
  },
  props: {
    // If the parent component disables autoLoad, then it should implement an initial Load button.
    autoLoad: {
      type: Boolean,
      default: true
    },
    // If restoreRowData is true and vuex contains row data, then use that data rather than refresh.
    restoreRowData: {
      type: Boolean,
      default: false
    },
    searchEnabled: {
      type: Boolean,
      default: true
    },
    // filterEnabled means server-side filtering is enabled.
    filterEnabled: {
      type: Boolean,
      default: true
    },
    transformFilterParams: Function,
    addItemEnabled: {
      type: Boolean,
      default: true
    },
    saveEnabled: {
      type: Boolean,
      default: true
    },
    deleteEnabled: {
      type: [Boolean, Function],
      default: false
    },
    confirmDelete: Boolean,
    importEnabled: {
      type: Boolean,
      default: true
    },
    bulkImportConfig: Function,
    routeUpdateTo: Object,
    routeUpdateNext: Function,
    routeLeaveNext: Function,
    beforeAdd: Function,
    afterSave: Function,
    promptDirtySave: {
      type: Boolean,
      default: true
    },
    allowBulkSelect: Boolean,
    bulkSelectOptions: Array,
    bulkActionState: String,
    bulkActionRowStatus: Object,
    getRowHeight: Function,
    clientSideActiveFilterEnabled: {
      type: Boolean,
      default: false
    },
    clientSideFilterFn: Function,
    denormEnabled: {
      type: [Boolean, Function],
      default: true
    },
    getRowClass: Function,
    allowLoadAll: Boolean
  },
  data () {
    return {
      editMode: false,
      selectedItem: null,
      editingIndex: null,
      editingItem: {},
      editingAgIndex: null,
      saving: false,
      deleting: false,
      bulkSelectOn: false,
      bulkSelectCount: 0,
      showActiveOnly: true,
      selectionColumnDef: {
        width: 50
      },
    }
  },
  computed: {
    eventBus () {
      return this.$store.getters[`${this.orchestrator}/eventBus`]
    },
    listNavigation () {
      return {
        length: this.gridRowData?.length ?? 0,
        index: this.editingAgIndex
      }
    },
    resourceName () {
      return this.$store.state[this.orchestrator].resourceName
    },
    addResourceText () {
      return this.$store.state[this.orchestrator].addResourceText
    },
    icon () {
      return this.$store.state[this.orchestrator].icon
    },
    routeName () {
      return this.$store.state[this.orchestrator].routeName
    },
    filterParams () {
      return this.$store.state[this.orchestrator].filter.filterParams
    },
    formDirty () {
      return this.$store.getters[`${this.orchestrator}/formDirty`]
    },
    gridRowData () {
      if (_.isEmpty(this.rowData)) return []
      let rowData = this.rowData
      if (this.clientSideFilterFn) {
        rowData = this.clientSideFilterFn(rowData)
      }
      if (this.clientSideActiveFilterEnabled && this.showActiveOnly) {
        // Assume null row.active is active.
        rowData = rowData.filter(row => row.active !== false)
      }
      return rowData
    },
    gridEmpty () {
      return this.rowData.length < 1
    },
    finalGridColumnDefs () {
      if (!this.bulkSelectOn) return this.allColumnDefsInPreferredOrder

      // TODO: Is is possible to retain selection items and their state when filtering?
      return [
        {
            suppressMovable: true,
            width: 250,
            hide: !this.bulkActionState,
            valueGetter: params => {
              const rowId = params.data.id
              // We'll show row status even if item is not selected, in order to handle the case
              // where a bulk action adds new items with status, e.g., distribute costing splitting
              // punches.
              // const rowStatus = params.node.isSelected() ? this.bulkActionRowStatus[rowId] : null
              const rowStatus = this.bulkActionRowStatus[rowId]
              return rowStatus
            },
            cellRenderer: 'fontAwesomeCellRenderer',
        }
      ].concat(
        this.allColumnDefsInPreferredOrder
          .map(colDef => ({
            suppressMovable: true,
            ...colDef
          }))
      )
    },
    entirePageBulkSelected () {
      // If all rows selected (i.e., bulkSelectCount === this.rowData.length)
      // and there are more results, then display alert above grid that not
      // all results are selected.
      return this.bulkSelectOn && !this.bulkActionState && this.bulkSelectCount === this.rowData.length && !!this.cursor
    },
    pluralResourceName () {
      return pluralize(this.resourceName)
    },
    loadingOverlayComponentParams () {
      return {
        // TODO: Fix should be showing error message and retry button.
        errorMessage: this.loadErrorMessage,
        retry: () => {
          this.search()
        }
      }
    },
    noRowsOverlayComponentParams () {
      return {
        parent: this,
        loadHandler: () => {
          // I tried using the other existing route and filter handlers, and coudln't get it to work.
          // This approach seems simple enough.
          this.search()
        }
      }
    },
    disableControls () {
      return this.loading || this.isPrinting
    },
    detailFormEnabled () {
      // TODO: Seems intent is whether parent passed slot content?
      return !!this.$slots['detail-form']?.()
    },
    reportTitle () {
      return this.pluralResourceName
    },
    customRowCount () {
      return this.rowData.length
    },
    showActiveOnlyStorageKey () {
      return `${this.orchestrator}-show-active-only`
    }
  },
  watch: {
    routeUpdateNext (next) {
      this.routeNext(next, 'update')
    },
    routeLeaveNext (next) {
      this.routeNext(next, 'leave')
    },
    bulkSelectOn (bulkSelectOn) {
      if (!this.gridApi) return // saw this happen once with a customer
      if (!bulkSelectOn) {
        this.gridApi.deselectAll()
        this.bulkSelectCount = 0
      }
      this.$emit('bulk-select-changed', bulkSelectOn)
    },
    // TODO: Display error message in grid overlay instead of toast.
    loadErrorMessage (loadErrorMessage) {
      if (loadErrorMessage) {
        this.$toast.error(loadErrorMessage, 5000)
      }
    },
    showActiveOnly (showActiveOnly) {
      // Persist to local storage.
      storage.setItem(this.showActiveOnlyStorageKey, showActiveOnly ? 'true' : 'false')
    }
  },
  methods: {
    onGridReady (params) {
      this.gridApi = params.api
    },
    // TODO: This method is already implemented on ManagesAgGrid.
    // TODO: Are we overriding it here?
    onActiveRouteViewChange (view) {

      if (!this.routeHandledAtleastOnce) {
        this.routeHandledAtleastOnce = true
        if (!view) {
          // We'll restore list if filters were already in use, or if there is saved data.
          if (this.filterOrSearchInUse) {
            // restore filter from vuex to component
            this.applyFilter(true)
            this.restoringList = true
            return
          } else if (this.$store.state[this.orchestrator].masterItems.length > 0) {
            this.restoringList = true
            // The route won't change, so we go straight to restoringList condition block below.
          } else if (!this.autoLoad) {
            return
          }
        }
        this.forceRefresh = !this.returningToList
      }

      // Dispatch handler in next tick, because if master detail components are just mounting now,
      // then child component watchers might miss changes, e.g., EmployeeForm originalData watcher
      // could be missed.
      this.$nextTick(() => {
        let forceRefresh = this.forceRefresh
        this.forceRefresh = false

        if ((!view && !forceRefresh) || this.returningToList) {
          this.returningToList = false
          this.closeForm()
        } else if (view === 'new' && this.detailFormEnabled) {
          this.openFormForNewItem()
        } else if (/^[0-9]+$/.test(view) && this.detailFormEnabled) {
          this.openFormForExistingItem(Number.parseInt(view))
        } else {
          forceRefresh = true

          let filterParams
          if (view) {
            try {
              filterParams = JSON.parse(view)
            } catch (e) {
              console.warn(`Invalid filter string on url: ${view}`)
              filterParams = {}
            }
          } else {
            filterParams = {}
          }
          this.$store.dispatch(`${this.orchestrator}/filter/setFilterParams`, filterParams)
        }

        if (this.restoringList) {
          const savedState = this.$store.state[this.orchestrator]
          this.rowData = savedState.masterItems
          this.cursor = savedState.cursor
          this.restoringList = false
          this.loadedOnce = true
        } else if (forceRefresh) {
          // Prevent duplicate call when redirecting from dashboard exceptions link.
          // There are 2 route changes: one for basic parameters, and then second one with all parameters.
          // We should ignore 2nd one.
          if (this.loading) {
            console.log('Ignore 2nd route change event, should not trigger load.')
            return
          }
          this.search()
        }
      })
    },

    routeNext (next, routeAction) {
      if (!next) return

      if (this.pendingRouteNext) {
        // already have pending route change
        next(false)
        return
      }

      if (this.editMode && !this.pendingCancel && (!this.returningToList || this.returningToListPromptDirty) && this.formDirty && this.promptDirtySave) {
        this.pendingRouteNext = next
        this.pendingCancel = false
        this.returningToListPromptDirty = false
        // form is dirty, so prompt whether to save before changing route
        this.showModal({
          component: SavePrompt,
          props: {
            onClose: decision => {
              if (decision === true) {
                // call save through form component, so it can do its
                // own custom processing, such as denorm prompt
                if (this.$refs.detailForm) this.$refs.detailForm.save()
              } else if (decision === false) {
                // proceed without saving
                // Note that we used to commit cancelChanges, but it seems we're always exiting the detail here,
                // so we changed to do that instead.
                this.$store.commit(`${this.orchestrator}/exitDetail`)
                this.pendingRouteNext = null
                next()
              } else {
                // cancel route change
                this.pendingRouteNext = null
                next(false)
              }
            }
          }
        })
      } else {
        this.pendingCancel = false

        // Exit detail unless we're navigating in or out of a deeper nested route under this detail.
        // An example of such a case is navigating to or from the pay run form into pay summary detail.
        // We don't want to exit detail in such a case, because the originalData detail (i.e., pay run) needs to be passed as a
        // prop into PaySummaryDetail.
        if (this.editMode && (routeAction !== 'update' || this.editingItem.id !== this.routeUpdateTo.params.view)) {
          this.$store.commit(`${this.orchestrator}/exitDetail`)
        }
        next()
      }
    },

    rowClicked (event) {
      this.$emit('row-clicked', event)
      if (!this.detailFormEnabled) return
      this.$router.push({ name: this.routeName, params: { view: event.node.data.id } }).catch(() => {})
    },

    returnToList (promptDirty) {
      if (!this.editMode) return
      this.returningToList = true
      if (promptDirty && this.formDirty) {
        this.returningToListPromptDirty = true // technical debt due to crazy logic in routeNext
        this.$router.push({ name: this.routeName, params: { view: JSON.stringify(this.filterParams) } }).catch(() => {})
      } else {
        this.$store.commit(`${this.orchestrator}/exitDetail`)
        this.applyFilter()
      }
    },

    getResultItem (data) {
      // The newer backend will return a `result` object,
      // whereas the django backend will return a `results`
      // object with a single item.
      return data.result || data.results?.[0] || data
    },

    saveEdit (data, { shouldDenorm, afterSaveAction } = {}) {
      this.saving = true
      this.showUpdatingMask()

      let savePromise
      const originalData = this.editingItem
      const creating = !data.id

      if (!creating) {
        savePromise = this.crudService.update(data,  { params: { denorm: shouldDenorm } })
          .then(result => {
            // TODO: Is there a way to update grid and this.rowData
            // TODO: in one call?
            const item = this.getResultItem(result)
            this.itemUpdated(item, result.supplementalData)
            return result
          })
      } else {
        savePromise = this.crudService.create(data)
          .then(result => {
            // TODO: Is there a way to update grid and this.rowData
            // TODO: in one call? ==> try ag-grid version 20 vue enhancements.
            const item = this.getResultItem(result)
            this.itemCreated(item, result.supplementalData)
            return result
          })
      }

      return savePromise
        .then(result => {
          this.hideMask()

          if (this.afterSave) {
            return this.afterSave(this.editingItem, originalData, result).then(() => result)
          }

          return result
        })
        // Wait a tick between updating originalData in vuex, and navigating to next route.
        // This is so that each detail form's watcher of originalData can update formData
        // to match, i.e., make formDirty false, and then the routeNext() method above doesn't
        // unnecessarily prompt to save again. On Chrome, for some reason it's unnecessary to
        // wait the extra tick, but on Edge browser it's required.
        .then(result => this.$nextTick().then(() => result))
        .then(result => {
          if (this.pendingRouteNext) {
            this.pendingRouteNext()
            this.pendingRouteNext = null
          } else if (afterSaveAction === 'close') {
            this.returnToList()
          } else if (creating) {
            // User clicked save and don't close on a new item, so change route to the new id.
            this.$router.push({ name: this.routeName, params: { view: this.getResultItem(result).id } })
          }
        })
        .catch(error => {
          // TODO: Submit 400 errors to bugsnag, since we want vuelidate to catch everything.
          // TODO: The prior comment needs to consider 400 errors that have nothing to do with form validation,
          // TODO: such as the backend not allowing pay class changes while there is a DRAFT pay run.
          this.showErrorMask(error)
          // Also display error in toast, because mask doesn't display well in the
          // absolutely positioned detail form.
          this.$toast.error(extractErrorMessage(error), 5000)
        })
        .finally(() => this.saving = false)
    },

    emitDetailChanged () {
      // The following mutation updates originalData state.
      // Each specific master-detail implementation (e.g., ShiftClassForm) has
      // a watcher for originalData to do manipulations to the new originalData
      // and then commit identical change to formData.
      this.$store.commit(`${this.orchestrator}/detailChanged`, {
        detailData: this.editingItem,
        detailIndex: this.editingIndex
      })
    },

    itemCreated (item, supplementalData) {
      if (supplementalData) {
        this.$store.commit(`${this.orchestrator}/supplementalDataAdded`, supplementalData)
      }

      this.rowData.push(item)
      this.editingItem = Object.assign({}, this.editingItem, item)
      this.editingIndex = this.rowData.length - 1
      this.emitRowDataToStore()
      this.gridApi.applyTransaction({ add: [item] })
      this.emitDetailChanged()
    },

    itemUpdated (item, supplementalData) {
      if (supplementalData) {
        this.$store.commit(`${this.orchestrator}/supplementalDataAdded`, supplementalData)
      }

      if (this.editingIndex !== null && this.editingIndex > -1) {
        this.editingItem = Object.assign({}, this.editingItem, item)
        this.rowData[this.editingIndex] = item
        this.emitDetailChanged()
      } else {
        // no editingItem when this event was emitted from bulk update
        const index = this.rowData.findIndex(row => row.id === item.id)
        if (index > -1) {
          this.rowData[index] = item
        }
      }

      this.emitRowDataToStore()
      // Using async here in case this is a bulk update, in which case ag-grid recommends using async transactions
      // for high-frequency updates: https://www.ag-grid.com/vue-grid/data-update-high-frequency/#async-transactions
      this.gridApi.applyTransactionAsync({ update: [item] })
    },

    itemDeleted (itemId) {
      this.setRowData(this.rowData.filter(row => row.id !== itemId))
      this.hideMask()
      this.returnToList()
    },

    abortSave () {
      if (this.pendingRouteNext) {
        // route change was waiting on save completion,
        // so we need to clear it.
        this.pendingRouteNext(false)
        this.pendingRouteNext = null
      }
    },

    cancelEdit () {
      if (this.pendingCancel || !this.editMode) return // dedupe
      this.pendingCancel = true
      this.$store.commit(`${this.orchestrator}/cancelChanges`)
      this.returnToList()
    },

    async deleteItem (itemId) {

      if (this.confirmDelete) {
        if (
          !await this.confirmModal({
            props: {
              body: `Are you sure you want to delete this ${this.resourceName}?`,
              centered: true
            }
          })
        ) {
          return
        }
      }

      this.deleting = true
      this.showUpdatingMask()

      await this.crudService.delete(itemId)
        .then(() => {
          const item = this.rowData.find(row => row.id === itemId)
          this.itemDeleted(itemId)
          this.$emit('item-deleted', { itemId, item })
        })
        .catch(error => {
          this.showErrorMask(error)
        })
        .finally(() => this.deleting = false)
    },

    addItem () {
      Promise.resolve(this.beforeAdd && this.beforeAdd())
        .then(() => this.$router.push({
          name: this.routeName,
          params: { view: 'new' }
        }))
        .catch(error => {
          console.warn('MasterDetail failed to navigate to route for new item', error)
        })
    },

    closeForm () {
      this.editMode = false
      this.editingItem = {}
      this.editingIndex = null
      this.editingAgIndex = null
    },

    openFormForNewItem () {
      this.editMode = true
      this.editingItem = {}
      this.$store.commit(`${this.orchestrator}/detailChanged`, {
        detailData: this.editingItem,
        detailIndex: null
      })
    },

    findIndexForItem (itemId) {
      return this.rowData.findIndex(item => item && item.id && item.id.toString() === itemId.toString())
    },

    getRowNodeAtIndex (agIndex) {
      return this.gridApi.getDisplayedRowAtIndex(agIndex)
    },

    openFormForExistingItem (itemId) {
      const index = this.findIndexForItem(itemId)
      const item = index > -1 && this.rowData[index]
      // grid api not set if we're loading web page directly at this item id
      const rowNode = this.gridApi && this.gridApi.getRowNode(itemId)

      return new Promise((resolve, reject) => {
        if (item) resolve(item)
        else {
          this.crudService.get(itemId)
            .then(data => {
              this.$store.commit(`${this.orchestrator}/supplementalDataAdded`, data.supplementalData)
              return data
            })
            .then(data => this.getResultItem(data))
            .then(e => resolve(e))
            .catch(error => reject(error))
        }
      })
        .then(item => {
          this.editMode = true
          this.editingItem = item
          this.editingIndex = index
          this.editingAgIndex = rowNode ? rowNode.childIndex : -1
          this.$store.commit(`${this.orchestrator}/detailChanged`, {
            detailData: this.editingItem,
            detailIndex: this.editingIndex
          })
        })
        .catch(() => this.returnToList())
    },

    // TODO: Need to throttle nextItem/prevItem if current navigation is in progress

    goToItemAtAgIndex (agIndex) {
      const agRowNode = this.getRowNodeAtIndex(agIndex)
      if (!agRowNode) return
      const index = this.findIndexForItem(agRowNode.id)
      const item = this.rowData[index]
      if (!item) return
      this.$router.push({ name: this.routeName, params: { view: item.id } }).catch(() => {})
    },

    nextItem (id) {
      if (this.editMode && this.editingItem.id === id && this.editingAgIndex < this.rowData.length - 1) {
        this.goToItemAtAgIndex(this.editingAgIndex + 1)
      }
    },

    prevItem (id) {
      if (this.editMode && this.editingItem.id === id && this.editingAgIndex > 0) {
        this.goToItemAtAgIndex(this.editingAgIndex - 1)
      }
    },

    selectionChanged (event) {
      this.bulkSelectCount = event.api.getSelectedNodes().length
    },

    bulkActionSelected (actionId) {
      const selectedItems = this.gridApi.getSelectedNodes().map(n => n.data)
      this.$emit('bulk-action-selected', actionId, selectedItems)
    },

    // This function is called by parent via a ref.
    invokeBulkActionOnAllRows (actionId) {
      this.bulkSelectOn = true
      this.gridApi.selectAll()
      this.bulkActionSelected(actionId)
    },

    bulkImportCompleted () {
      // Refresh grid
      this.search()
    },

    rowDataChanged () {
      this.restoreSelectionState()
    },

    rowDataUpdated () {
      this.restoreSelectionState()
    },

    sortChanged () {
      this.refreshCells()
    },

    refreshCells () {
      if (this.gridApi) {
        this.gridApi.refreshCells()
      }
    },

    applyGridTransaction ({ transaction, shouldUpdateDetailForm }) {
      // The reason for the shouldUpdateDetailForm parameter is to avoid racing conditions if the event emitter
      // is exiting the detail form at the same time as emitted this event.

      this.saveSelectionState()

      if (shouldUpdateDetailForm) {
        // Update detail form.
        const editingItemId = _.get(this.editingItem, 'id')
        const updatedItem = editingItemId && !_.isEmpty(transaction.update)
          ? transaction.update.find(item => item.id === editingItemId)
          : null
        if (updatedItem) {
          this.editingItem = Object.assign({}, this.editingItem, updatedItem)
          this.emitDetailChanged()
        }
      }

      // Update row data.
      if (!_.isEmpty(transaction.add)) {
        this.rowData.push(...transaction.add)
        // Don't apply via transaction, because it causes a double add.
        delete transaction.add
      }
      if (!_.isEmpty(transaction.update)) {
        // TODO: If any of the items in the update list is not already in rowData, should we add them a new?
        // TODO: An example where this scenario can occur is when the user loads the web page directly to a punch
        // TODO: to distribute costing. The original updated punch will not be added to the master list, whereas
        // TODO: any new punches will be added.
        transaction.update.forEach(item => {
          const index = this.rowData.findIndex(i => i.id === item.id)
          if (index > -1) this.rowData[index] = item
        })
      }
      if (!_.isEmpty(transaction.remove)) {
        transaction.remove.forEach(itemId => {
          // TODO: Fix next line TypeError · Cannot read properties of undefined (reading 'id')
          // TODO: Seems to occur after saving an existing punch. Maybe it was loaded directly, but not in grid?
          const index = this.rowData.findIndex(i => i.id === itemId)
          // TODO: I think we need to slice, not delete.
          if (index > -1) delete this.rowData[index]
        })
      }

      this.emitRowDataToStore()
      this.gridApi.applyTransactionAsync(transaction)
      // TODO: Sorting? For punches, the default sort order is: queryDt, last name, first name.
    },
    customData () {
      return Promise.resolve({
        items: this.rowData
      })
    },

    async exportGrid (exportType) {

      // Prompt to load all results before exporting, if applicable.
      if (this.allowLoadAll && this.cursor) {
        const value = await this.confirmModal({
          props: {
            body: 'Do you want to load all results before exporting?',
            headerClass: 'p-2 border-bottom-0',
            footerClass: 'p-2 border-top-0',
            centered: true,
            okTitle: 'Yes',
            cancelTitle: 'No'
          }
        })
        if (value) {
          await this.loadAllRemainingResults()
          if (this.loadErrorMessage) {
            return
          }
        }
      }

      switch (exportType) {
        case 'print':
          this.print()
          break
        case 'csv':
          this.csv()
          break
        case 'excel':
          this.excel()
          break
        case 'pdf':
          this.pdf()
          break
        default:
          console.warn(`[exportGrid] invalid type ${exportType}`)
          break
      }
    }
  },
  created () {
    if (this.clientSideActiveFilterEnabled) {
      this.showActiveOnly = storage.getItem(this.showActiveOnlyStorageKey) !== 'false'
    }
  },
  mounted () {
    this.eventBus.on('deleteItem', this.deleteItem)
    this.eventBus.on('updateItem', this.saveEdit)
    this.eventBus.on('itemCreated', this.itemCreated)
    this.eventBus.on('itemUpdated', this.itemUpdated)
    this.eventBus.on('itemDeleted', this.itemDeleted)
    this.eventBus.on('applyGridTransaction', this.applyGridTransaction)
  },
  beforeDestroy () {
    this.eventBus.off('deleteItem', this.deleteItem)
    this.eventBus.off('updateItem', this.saveEdit)
    this.eventBus.off('itemCreated', this.itemCreated)
    this.eventBus.off('itemUpdated', this.itemUpdated)
    this.eventBus.off('itemDeleted', this.itemDeleted)
    this.eventBus.off('applyGridTransaction', this.applyGridTransaction)
  }
}
</script>
<style lang="scss" scoped>
@import '~bootstrap/scss/functions';
@import '~bootstrap/scss/variables';
@import '~bootstrap/scss/mixins';

.master-detail {

  display: flex;
  flex-direction: column;

  // But not we also need to give this container an explicit
  // height in order for the detail form to set its own height 100%.
  // Height is total viewport height - heights of breadcrumb bar, tab
  // panel header, and footer. In actuality, we added back 25px to fit
  // the full height more closely.
  // TODO: This approach is really brittle, and will be hard to re-use
  // TOOD: elsewhere.
  height: calc(100vh - 55px - 40px - 50px + 25px);
  // Remove bottom margin to prevent footer from being pushed down.
  // Really, this whole layout styling is technical debt.
  margin-bottom: 0 !important;

  // Position relative in order to set detail form
  // absolute position above it. But turn off when
  // printing.
  @media screen {
    position: relative;
  }

  &.edit-mode {
    > * {
      display: none;
    }
    :deep(.detail-form) {
      display: block;
    }
  }

  &.customize-pinned {
    // TODO: Should we resize grid tools too?
    .master-grid {
      // When sidebar is pinned, then we need to adjust main page width accordingly.
      // On xs screen, we need to hide all children except the sidebar.
      // https://bootstrap-vue.org/docs/components/sidebar#width
      @include media-breakpoint-up(sm) {
        width: calc(100% - 320px);
      }
      @include media-breakpoint-only(xs) {
        > :not(.b-sidebar-outer) {
          visibility: hidden;
        }
      }
    }
  }

  .bulk-select-page-alert {
    margin-top: 1rem;
  }

  .show-active-only {
    margin-left: 25px;
    margin-right: 10px;
    padding-top: 7px;
    font-size: .8rem;
  }

  .master-grid {
    margin-top: 10px;
    flex: 1;

    // Enable click events for custom NoRowsOverlay component.
    :deep(.ag-overlay) {
      pointer-events: auto;
    }
  }
}
</style>
